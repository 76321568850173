import diplo from '../../img/Diplo.svg'
function Nav({suburl}) {
  return (
    <nav>
        <div className="nav-left ">
            <div className="img"></div>
            <span className="name">{suburl.replace('-',' ')} GDC advisor</span>
        </div>
        <div className="nav-middle"></div>
        <div className="nav-right">
          <img src={diplo} alt="" />
          {/* <h2>by Diplo</h2> */}
        </div>
    </nav>
  )
}

export default Nav
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";
import Main from "./components/Main"

function App() {
  const [error, setError] = useState()
  const [auth, setAuth] = useState()
    
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main suburl={'EU'}/>} />
        <Route path="/brazil" element={<Main suburl={'Brazil'}/>} />
        <Route path="/african-union" element={<Main suburl={'African-Union'}/>} />
        <Route path="/china" element={<Main suburl={'China'}/>} />
        <Route path="/usa" element={<Main suburl={'USA'}/>} />
        <Route path="/eu" element={<Main suburl={'EU'}/>} />
        <Route path="/caribbean-community" element={<Main suburl={'Caribbean-Community'}/>} />
        <Route path="/private-sector" element={<Main suburl={'Private-sector'}/>} />
        <Route path="/civil-society" element={<Main suburl={'Civil-society'}/>} />
        <Route path="/ethiopia" element={<Main suburl={'Ethiopia'}/>} />
        <Route path="/new_york" element={<Main suburl={'New-York'}/>} />
      </Routes>
    </Router>
  );
}

export default App;

import Nav from './navigation/Nav';
import Chatroom from './chat/Chatroom';
import AlertDialogSlide from './util/Alert';
import { useState,useEffect,useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";



import backend from '../../package.json'
const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#00818c',
      darker: '#006c75',
    }
  },
});

function Main({suburl}) {
  const [info,setInfo]=useState(false);
  const [user_id,setUserId]=useState('')

  useEffect(() => {
    if(!user_id){
      axios.get(backend.backend+'/').then((data)=>{
        setUserId(data.data.id) 
        console.log(data.data.id);
      }).catch((e)=>{console.log(e);})
        
    }
  }, [user_id])
  
  
  return (
    <>
    <ThemeProvider theme={theme}>
      <Nav suburl={suburl}></Nav>
      {/* <AlertDialogSlide info={info} setInfo={setInfo}></AlertDialogSlide> */}
      <Chatroom user_id={user_id} suburl={suburl.toLowerCase()}></Chatroom>
    </ThemeProvider>
    </>
  );
}

export default Main;

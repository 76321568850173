import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Russeau from '../../img/Rousseau.jpg'

import InfoIcon from '@mui/icons-material/Info';

import { createTheme } from '@mui/material/styles';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    if(!props.info &&!localStorage.getItem("ruso_info")){
      handleClickOpen()
    }
  }, [props.info])

  const handleClickOpen = () => {
    localStorage.setItem("ruso_info", '1')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setInfo(true)
  };

  return (
    <>
      <InfoIcon className='info-btn' onClick={handleClickOpen} fontSize="large" color="primary"/>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Chat with documents"}<span className='diplo'>{' by diplo'}</span></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* <div className="alert-img">

              <img src={Russeau} alt="" width="240" height="300" style={{float: 'left', margin: '5px'}}/>
            </div> */}
          <div>


          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras accumsan tempus urna, malesuada lacinia magna sodales at. Nam blandit nisi lobortis massa sollicitudin interdum. Fusce at dui vel ex tincidunt scelerisque. Phasellus commodo eros eros, ut porttitor risus rhoncus ornare. Integer et nibh risus. Mauris massa ligula, sagittis vitae urna eget, suscipit fringilla nulla. Sed id velit consectetur, posuere nisl et, pellentesque ligula. Vestibulum nec augue vel elit aliquam scelerisque. Pellentesque a ligula purus. Nam eu odio diam. Nunc consectetur quis ante non elementum. Ut pulvinar turpis augue, id finibus leo eleifend id. Nunc varius velit eget metus consequat hendrerit.

Nunc pulvinar metus a felis ornare, eget luctus velit rutrum. Nullam dictum dolor vitae sapien ultricies semper. Donec eu feugiat elit. Quisque augue odio, rutrum at ex id, tincidunt lacinia dolor. Aenean vel molestie lorem, sit amet porttitor massa. Sed tristique enim nec enim tempor, a rhoncus leo elementum. Cras id consectetur tortor. Sed tristique vel nunc sed consequat. Nunc et erat sed nunc consequat ultrices. Aliquam ultricies faucibus diam, eget fermentum ipsum. Nunc sollicitudin convallis lectus ut consectetur.

Phasellus scelerisque, felis eget blandit egestas, quam neque sagittis justo, a tempor dui purus non odio. Curabitur id purus vulputate, viverra justo vitae, tincidunt purus. Cras finibus commodo urna et fringilla. Fusce ac justo eu nibh placerat suscipit. Donec id augue vitae metus consequat congue lobortis eget ante. Praesent tincidunt urna non est hendrerit venenatis. Morbi mattis nunc a porta molestie. Ut et ante pharetra, malesuada enim id, elementum tortor.

Sed sed nisl nec tellus vestibulum efficitur. Sed a lorem feugiat nulla sollicitudin tincidunt sit amet rutrum orci. Sed id augue eget turpis maximus efficitur ac fermentum nunc. Pellentesque rhoncus sollicitudin nisi lacinia viverra. Aliquam a vehicula enim, quis rutrum augue. Sed feugiat luctus blandit. Maecenas hendrerit tellus at tellus finibus, a fringilla nunc condimentum. Nulla lobortis, nunc vel tincidunt hendrerit, quam eros commodo eros, vitae dapibus ante purus sit amet erat. Praesent vel accumsan magna, ut rutrum magna. Quisque et posuere ante. Nullam vitae felis justo. Praesent augue lacus, dictum nec consequat nec, vehicula faucibus turpis. Proin neque nunc, fringilla at lacinia ac, gravida in urna.

Proin in sapien laoreet, finibus quam eu, aliquet metus. Donec tempor, est id vehicula sodales, mi massa porttitor arcu, vitae maximus tellus diam sed sapien. Duis bibendum orci nec ante consectetur luctus. Proin id odio scelerisque, lacinia velit ac, maximus purus. Cras bibendum turpis id quam posuere volutpat. Aliquam lobortis volutpat blandit. Morbi vehicula luctus dolor ac sagittis. Etiam eget feugiat purus. Sed pellentesque neque quam, et congue quam pellentesque lacinia. Mauris suscipit felis eget ipsum rutrum posuere eu a nunc. Proin nec pellentesque lectus. Morbi auctor est sit amet nunc condimentum, vitae posuere tortor consectetur. 
          </div>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
